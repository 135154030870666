<template>
  <v-container fill-height>
      <v-row align='center' justify='center'>

        <v-col cols=12 sm=7 md=6 lg=4 xl=3>
          <v-card class='pa-6 rounded-xl' elevation=5 :disabled='loading'>
            <form
              @submit.prevent='clickSubmit'
            >
              <v-img src="@/assets/mgg.svg"></v-img>
              <v-row class='mt-4'>
                <v-col cols=12 v-show='loginMode||resetMode'><v-text-field outlined hide-details label='Email' ref='email' v-model='email' /></v-col>
                <v-col cols=12 v-show='loginMode||newPassMode'><v-text-field outlined hide-details :label='passwordText' type='password' ref='pw' v-model='password' /></v-col>
                <v-col cols=12><v-btn large block class='primary' type='submit' :loading="loading">{{submitText}}</v-btn></v-col>
                <v-col cols=12 v-show='!newPassMode'><v-btn small text class='primary--text float-right' @click='toggleMode'>{{toggleModeText}}</v-btn></v-col>
              </v-row>
            </form>
            
            <v-divider class="my-4" v-if="pinLogins.length && loginMode" />
            <div class="text-center" v-if="pinLogins.length && loginMode">
              <v-tooltip bottom v-for="(item,i) in pinLogins" :key="i">
                <template #activator="{on,attrs}">
                  <v-btn fab text  v-on="on" v-bind="attrs" @click="login_pin(item)">
                    <v-avatar class="white--text text-h5" color="primary" @click="()=>{}">{{item.user.name_first[0]}}{{item.user.name_last[0]}}</v-avatar>
                  </v-btn>
                </template>
                {{item.user.name_first}} {{item.user.name_last}}
              </v-tooltip>
            </div>

          </v-card>
        </v-col>
      </v-row>
  </v-container>

</template>

<style lang="scss" scoped>
</style>


<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Login',
  components: {
  },
  props: {
    resetCode: { type: String, required: false, default: null },
  },
  data(){ return {
    loading: false,
    email: "",
    password: "",
    mode: this.resetCode ? "newpass" : "login",
    pinLogins: [],
  }},
  computed: {
    ...mapGetters('AUTH', ['LOGGED_IN', 'BROWSER_ID']),
    loginMode(){ return this.mode == 'login'; },
    resetMode(){ return this.mode == 'reset'; },
    newPassMode(){ return this.mode == 'newpass'; },
    toggleModeText(){ return this.mode == 'login' ? "Reset Password" : "Sign In" },
    submitText(){ return this.loginMode ? "Sign In" : "Reset Password" },
    passwordText(){ return this.loginMode ? "Password" : "New Password" },
  },
  watch:{
    submitText: { handler(){ let t = this.submitText; this.SET_TITLE(t); }, immediate: true },
  },
  methods: {
    ...mapActions("UI", ["SET_TITLE", "NOTIFY", "NAVIGATE", "ALERT", "PIN"]),
    ...mapActions("AUTH", ["LOGIN"]),
    ...mapActions("API", ["API"]),

    submitLogin(){
      this.loading = true;
      this.API({ method: 'POST', endpoint: 'auth/login', data: {
        email: this.email, password: this.password, browser: this.BROWSER_ID,
      }})
      .then(token => {
        this.LOGIN(token);
        this.NOTIFY('Welcome!');
        this.NAVIGATE('/dash');
      })
      .catch(() => { this.$refs.pw.$el.querySelector('input').select(); })
      .finally(() => { this.loading = false; });
    },

    requestResetEmail(){
      this.loading = true;
      this.API({ method: 'POST', endpoint: 'auth/reset_request', data: {
        email: this.email
      }})
      .then(() => {
        this.mode = 'login';
        this.ALERT({ title: "Almost done...", message: "We have emailed you a link to reset your password." });
      })
      .catch(() => { this.$refs.email.focus(); })
      .finally(() => { this.loading = false; });
    },

    changePassword(){
      this.loading = true;
      this.API({ method: 'POST', endpoint: 'auth/reset_password', data: {
        resetCode: this.resetCode,
        password: this.password,
      }})
      .then(token => {
        this.LOGIN(token);
        this.NOTIFY('Password Changed.');
        this.NOTIFY('Welcome!');
        this.NAVIGATE('/dash');
      })
      .catch(() => { this.$refs.pw.focus(); })
      .finally(() => { this.loading = false; });
    },

    clickSubmit(){
      if(this.loginMode) this.submitLogin();
      if(this.resetMode) this.requestResetEmail();
      if(this.newPassMode) this.changePassword();
    },

    checkResetCode(){
      this.loading = true;
      this.API({ method: 'POST', endpoint: 'auth/reset_check', data: {
        resetCode: this.resetCode
      }})
      .then(() => {
        this.loading = false;
        this.$refs.pw.focus();
      })
      .catch(()=>{
        this.loading = false;
        this.mode = 'login';
        this.$nextTick(()=>{ this.$refs.email.focus(); });
        this.NAVIGATE('/login');
      });
    },

    toggleMode(){ this.mode = this.mode == 'login' ? 'reset' : 'login'; },

    login_pin(pinLogin){
      this.PIN({ title: `${pinLogin.user.name_first} ${pinLogin.user.name_last}`, message: "Please enter PIN to continue:" })
      .then(pin => {
        this.API({ method: 'POST', endpoint: 'auth/login_pin', data: {
          pin_id: pinLogin.xid, pin
        }})
        .then(token => {
          this.LOGIN(token);
          this.NOTIFY('Welcome!');
          this.NAVIGATE('/dash');
        })
        .catch(this.loadPinLogins);
      });
    },

    loadPinLogins(){
      this.loading = true;
      this.API({ method: 'GET', endpoint: `auth/pin_logins/${this.BROWSER_ID}` })
      .then(pinLogins => { this.pinLogins = pinLogins; })
      .finally(() => { this.loading = false; })
    }

  },
  mounted(){
    this.$nextTick(()=>{
      if(this.loginMode) this.$refs.email.focus();
      if(this.LOGGED_IN) this.NAVIGATE('/dash');
      if(this.newPassMode) this.checkResetCode();
      this.loadPinLogins();
    });
  }
}
</script>
