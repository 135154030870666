var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fill-height": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "7",
      "md": "6",
      "lg": "4",
      "xl": "3"
    }
  }, [_c('v-card', {
    staticClass: "pa-6 rounded-xl",
    attrs: {
      "elevation": "5",
      "disabled": _vm.loading
    }
  }, [_c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.clickSubmit.apply(null, arguments);
      }
    }
  }, [_c('v-img', {
    attrs: {
      "src": require("@/assets/mgg.svg")
    }
  }), _c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loginMode || _vm.resetMode,
      expression: "loginMode||resetMode"
    }],
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    ref: "email",
    attrs: {
      "outlined": "",
      "hide-details": "",
      "label": "Email"
    },
    model: {
      value: _vm.email,
      callback: function callback($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  })], 1), _c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loginMode || _vm.newPassMode,
      expression: "loginMode||newPassMode"
    }],
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    ref: "pw",
    attrs: {
      "outlined": "",
      "hide-details": "",
      "label": _vm.passwordText,
      "type": "password"
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "primary",
    attrs: {
      "large": "",
      "block": "",
      "type": "submit",
      "loading": _vm.loading
    }
  }, [_vm._v(_vm._s(_vm.submitText))])], 1), _c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.newPassMode,
      expression: "!newPassMode"
    }],
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "primary--text float-right",
    attrs: {
      "small": "",
      "text": ""
    },
    on: {
      "click": _vm.toggleMode
    }
  }, [_vm._v(_vm._s(_vm.toggleModeText))])], 1)], 1)], 1), _vm.pinLogins.length && _vm.loginMode ? _c('v-divider', {
    staticClass: "my-4"
  }) : _vm._e(), _vm.pinLogins.length && _vm.loginMode ? _c('div', {
    staticClass: "text-center"
  }, _vm._l(_vm.pinLogins, function (item, i) {
    return _c('v-tooltip', {
      key: i,
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on,
            attrs = _ref.attrs;
          return [_c('v-btn', _vm._g(_vm._b({
            attrs: {
              "fab": "",
              "text": ""
            },
            on: {
              "click": function click($event) {
                return _vm.login_pin(item);
              }
            }
          }, 'v-btn', attrs, false), on), [_c('v-avatar', {
            staticClass: "white--text text-h5",
            attrs: {
              "color": "primary"
            },
            on: {
              "click": function click() {}
            }
          }, [_vm._v(_vm._s(item.user.name_first[0]) + _vm._s(item.user.name_last[0]))])], 1)];
        }
      }], null, true)
    }, [_vm._v(" " + _vm._s(item.user.name_first) + " " + _vm._s(item.user.name_last) + " ")]);
  }), 1) : _vm._e()], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }